import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Sidebar from "../components/Repeating/SidebarCta";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Del Mar Conservatorship Lawyer",
      content: (
        <>
          <p>
            A conservatorship gives you the legal framework to manage the
            affairs of a loved one who is unable to take care of themselves
            either physically or mentally. It allows you to handle their
            financial matters and attend to their personal needs to ensure their
            comfort and security.
          </p>
          <p>
            It can be a difficult decision to seek a conservatorship and having
            strong legal advice can help.
          </p>
          <p>
            Our experienced conservatorship attorneys in Del Mar have decades of
            collective expertise. We'll help you evaluate whether this best fits
            your situation and guide you through the process should you pursue
            conservatorship.
          </p>
          <a href="https://briertonjones.com/conservatorship-lawyer/">
            Learn More About Conservatorship
          </a>
        </>
      ),
    },
    {
      title: "Del Mar Guardianship Lawyer",
      content: (
        <>
          <p>
            When parents are incapable of making vital decisions to care for a
            child, the legal system can intervene by appointing a guardian. The
            parents retain their parental rights and can ask for reasonable
            contact or visitation, but a guardian supervised by the court takes
            over the responsibility of ensuring the child is well cared for.
          </p>
          <p>
            At Brierton, Jones & Jones, LLP, we know that securing guardianship
            to ensure a child’s well-being can be a complex and emotionally
            charged journey. Our Del Mar attorneys are here to help make it as
            smooth and seamless as possible— let our seasoned legal team be your
            guide through the process.
          </p>
          <a href="https://briertonjones.com/guardianship-lawyer/">
            Learn More About Guardianship
          </a>
        </>
      ),
    },
    {
      title: "Del Mar Special Needs Trust Lawyer",
      content: (
        <>
          <p>
            For parents who care for a child with a disability, estate planning
            is a key factor in securing their future well-being. A cornerstone
            of this planning is establishing a special needs trust, which allows
            you to earmark assets for your loved one. It can even help ensure
            that, as a disabled adult, they still receive essential government
            benefits, such as SSI and Medi-Cal.
          </p>
          <p>
            There are different types of special needs trusts, such as
            first-party, third-party, or pooled that our lawyers can review with
            you. Plan today and gain the peace of mind that your loved one will
            be cared for, even when you are gone.
          </p>
          <a href="https://briertonjones.com/special-needs-trust-lawyer/">
            Learn More About Special Needs Trust
          </a>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust Administration Del Mar | Brierton, Jones & Jones"
        description="Looking for trust administration lawyers in Del Mar? Get expert assistance with trust administration & ensure your estate is managed efficiently. Call today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <article>
        <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
          <div className="relative">
            <div className="container">
              <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
                <div className="md:pr-6 lg:pr-12">
                  <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                    SECURE YOUR LEGACY
                  </div>
                  <h1>Del Mar Trust Administration Lawyer</h1>
                  <p>
                    Administering a trust is an important responsibility, but
                    you don’t have to do it alone. Whether you need assistance
                    with asset management, settling debts, or maintaining
                    compliance, the Brierton, Jones & Jones, LLP legal team can
                    help.
                  </p>
                  <p>
                    We offer 30 years of experience in trust administration and
                    proudly serve the community of Del Mar with commitment,
                    compassion, and respect.
                  </p>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Schedule a Consultation"
                  />
                </div>

                <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[45vw] md:pl-6 lg:pl-12">
                  <StaticImage
                    src="../images/city pages/Del Mar.png"
                    loading="eager"
                    className="h-full"
                    imgClassName="object-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-20 pt-14 md:mb-32 md:pt-20">
          <div className="container">
            <div className="flex justify-between md:space-x-14 lg:space-x-28">
              <div>
                <div className="mb-12 md:mb-18">
                  <h2>Why You Need a Trust Administration Lawyer</h2>
                  <p>
                    If 30 years of experience have shown us anything, it's that
                    trust cases can be legally and emotionally
                    overwhelming—especially when placed in the wrong hands.
                  </p>
                  <p>
                    That’s why Del Mar has relied on our legal team for over 30
                    years.{" "}
                  </p>

                  <p>
                    We simplify intricate trust administration cases with a
                    personalized approach, upholding your wishes and protecting
                    your loved ones' interests. With us, your trust
                    administration case is in reliable and caring hands—a
                    commitment we've upheld for over three decades.
                  </p>
                </div>

                <div>
                  <h3>Put Your Trust in the Right Hands</h3>
                  <p>
                    Successful trust administration requires experience,
                    empathy, and expertise. That's precisely how we handle every
                    step of your case, from asset inventory and distribution to
                    accounting, tax payment, and resolution.
                  </p>
                </div>
                <div>
                  <h3>Assurance and Peace of Mind – Always</h3>
                  <p>
                    Trustees are often surprised to learn they can face
                    financial accountability for unintentional errors. Our
                    approach ensures swift case resolution, free from costly
                    mistakes, granting you and your family total peace of mind.
                  </p>
                </div>
                <div className="pt-[120px]">
                  <h3>Related Practice Areas</h3>
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                  >
                    {accordionContent.map((item, i) => (
                      <AccordionItem
                        uuid={i}
                        className="border-b border-gray-100 pb-5"
                      >
                        <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                    {item.title}
                                  </h3>
                                  <i
                                    className={`far ml-2 text-xl text-seafoam ${
                                      state.expanded
                                        ? "fa-minus"
                                        : "fa-chevron-down"
                                    }`}
                                  ></i>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          {item.content}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact us"
                    className="mt-8"
                  />
                </div>
              </div>

              <div className="hidden md:block">
                <div className="sticky top-24">
                  <aside>
                    <div className="ml-auto mt-14 w-[298px] rounded-3xl border border-seafoam px-4 py-6">
                      <div className="mb-4 font-display text-2xl font-semibold text-typography-heading">
                        Schedule a Consultation
                      </div>
                      <p className="font-display text-lg">
                        When you need a Del Mar trust administration attorney to
                        help you put everything in order, Brierton, Jones &
                        Jones, LLP is here for you.
                      </p>

                      <div className="space-y-4">
                        <ButtonGhost
                          href="tel:619-485-5394"
                          text="(619) 485-5394"
                          className="w-full"
                        />
                        <ButtonSolid
                          modal="modal-contact"
                          text="Send a Message"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-20">
            <StaticImage
              src="../images/city pages/Del Mar Map.jpg"
              loading="eager"
              className="w-full"
            />
          </div>
        </section>
      </article>
      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="When you need a San Diego estate planning attorney to help you put everything in order, Brierton, Jones & Jones, LLP is here for you." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
